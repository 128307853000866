<template>
  <section class="base-page">
    <PageHeader class="base-shadow">
      <template #right>
        <el-button
          class="mt-r"
          size="small"
          type="primary"
          icon="el-icon-collection-tag"
          @click="navToSolutions"
          v-if="checkPermission(['SOLUTION_ADMIN'])"
          >标签管理</el-button
        >
      </template>
    </PageHeader>
    <keep-alive><FilterSection @change="onFilterChange"/></keep-alive>
    <BasicTable
      showSelection
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <el-button
          size="small"
          class="mt-r"
          type="primary"
          icon="el-icon-document-add"
          @click="addRow"
          v-if="checkPermission(['PRODUCT_ADMIN'])"
          >新增</el-button
        >
        <el-checkbox class="mt-l mt-r" v-model="showDisable" @change="onSearch"
          >显示禁用</el-checkbox
        >
        &nbsp; &nbsp;
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'lastModifiedAt'">{{
              scope.row.lastModifiedAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'postAt'">{{
              scope.row.postAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'disabled'">
              <el-tag
                :type="scope.row.disabled ? 'danger' : 'success'"
                @click="toggleDisableRow(scope.row, scope.$index)"
                >{{ scope.row.disabled ? "已禁用" : "已启用" }}</el-tag
              >
            </span>
            <span v-else-if="column.prop === 'titleCn'">{{
              scope.row.title.cn
            }}</span>
            <span v-else-if="column.prop === 'titleEn'">{{
              scope.row.title.en
            }}</span>

            <span v-else-if="column.prop === 'content'">{{
              scope.row.content ? scope.row.content.cn : "/"
            }}</span>
             <span v-else-if="column.prop === 'introduction'">{{
              scope.row.introduction ? scope.row.introduction.cn : "/"
            }}</span>
            

            <el-image
              v-else-if="column.prop === 'coverImageUrl'"
              style="width: 100%; height: 66px;"
              :src="scope.row.coverImageUrl"
              :preview-src-list="coverImages"
            >
            </el-image>
            <span v-else-if="column.prop === 'tags'">
              {{
                scope.row.tags.length > 0
                  ? scope.row.tags.map((item) => item.name).join("、")
                  : "/"
              }}
            </span>
            <span v-else-if="column.prop === 'products'">
              {{ scope.row.products.length }} 个
            </span>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-dropdown>
              <span class="el-dropdown-link drop-down-menu-link">
                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="scope.row.canUpdate"
                  @click.native.prevent="editRow(scope.row, scope.$index)"
                  >编辑</el-dropdown-item
                >

                <el-dropdown-item
                  v-if="scope.row.canDisable"
                  :class="[scope.row.disabled ? 'text-success' : 'text-danger']"
                  @click.native.prevent="
                    toggleDisableRow(scope.row, scope.$index)
                  "
                >
                  {{ scope.row.disabled ? "启用" : "禁用" }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          class="mt-r"
          size="small"
          @click="onMultipleToggleDisable(false)"
          :disabled="multipleSelected.length <= 0"
          >批量启用 {{ multipleSelected.length }}</el-button
        >
        <el-button
          class="mt-r"
          size="small"
          @click="onMultipleToggleDisable(true)"
          :disabled="multipleSelected.length <= 0"
          >批量禁用 {{ multipleSelected.length }}</el-button
        >
      </template>
    </BasicTable>
    <Form
      :show="formDialog"
      :sid="currentId"
      @cancel="onFormCancel"
      @success="onFormSuccess"
      @close="onFormClose"
    />
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import {
  GetSolutionsList,
  ToogleDisableByIds,
  GetDisableCheck,
  GetUpdateCheck,
} from "./api";
import PageHeader from "@/components/PageHeader";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import {
  GetTableOrderParams,
  onClearFilter,
  FormatMultipleItemIds,
  GetTableDisableMessage,
} from "@/utils/table";
import Form from "./components/Form";
import FilterSection from "./components/FilterSection";
import config from "@/config";
export default {
  name: "ArticlesList",
  components: {
    BasicTable,
    Form,
    FilterSection,
    PageHeader,
  },
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      multipleFormDialog: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "postAt",
      orderDirection: "descending",
      currentId: null,
      multipleSelected: [],
      showDisable: false,
      orderPropObj: {},
    };
  },
  computed: {
    coverImages() {
      return this.tableData.map((item) => item.coverImageUrl);
    },
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        this.tableColumns = [
          {
            prop: "coverImageUrl",
            label: "封面图",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "postAt",
            label: "发布日期",
            minWidth: 150,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "titleCn",
            label: "中文名",
            minWidth: 150,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "titleEn",
            label: "英文名",
            minWidth: 150,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "tags",
            label: "标签",
            minWidth: 150,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "products",
            label: "产品",
            minWidth: 150,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "introduction",
            label: "摘要",
            minWidth: 180,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "content",
            label: "内容",
            minWidth: 120,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "seq",
            label: "次序",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "id",
            label: "ID",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },

          {
            prop: "createdAt",
            label: "创建日期",
            showColumn: true,
            width: 200,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdByName",
            label: "创建人",
            showColumn: true,
            minWidth: 150,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "lastModifiedAt",
            label: "更新日期",
            showColumn: true,
            width: 200,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "lastModifiedByName",
            label: "更新人",
            showColumn: true,
            align: "center",
            minWidth: 150,
            sortable: "custom",
          },
          {
            prop: "disabled",
            label: "状态",
            showColumn: true,
            width: 100,
            align: "center",
            fixed: null,
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            align: "center",
            showColumn: true,
            label: "操作",
            width: 100,
          },
        ];
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },

    onSearch() {
      this.page = 0;
      this.getTableData();
    },
    getTableData() {
      this.tableLoading = true;
      GetSolutionsList({
        page: this.page,
        size: this.size,
        sort: GetTableOrderParams(
          this.orderProp,
          this.orderDirection,
          this.orderPropObj
        ),
        disabled: this.showDisable,
        ...this.tableFilters,
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.tableData = this.tableData.map((item) => {
            return {
              ...item,
              canUpdate: true,
              canDisable: true,
            };
          });
          this.onUpdateCheck();
          this.onDisableCheck();
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.page = 0;
      this.onSearch();
    },
    onClearAndRefresh() {
      this.orderProp = "seq";
      this.orderDirection = "descending";
      this.onResetFilter();
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.page = 0;
      this.onRefresh();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "seq";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
    addRow() {
      this.currentId = null;
      this.formDialog = true;
    },
    editRow(row) {
      if (!row.canUpdate) {
        this.$message.warning("没有权限执行此操作!");
        return false;
      }
      this.currentId = row.id;
      this.formDialog = true;
    },

    async onUpdateCheck() {
      try {
        let ids = this.tableData.map((item) => item.id);
        if (ids.length > 0) {
          let res = await GetUpdateCheck(ids);
          this.tableData = this.tableData.map((item) => {
            item.canUpdate = res.data;
            return item;
          });
        }
      } catch (error) {
        this.tableData = this.tableData.map((item) => {
          item.canUpdate = true;
          return item;
        });
      }
    },
    async onDisableCheck() {
      try {
        let ids = this.tableData.map((item) => item.id);
        if (ids.length > 0) {
          let res = await GetDisableCheck(ids);
          this.tableData = this.tableData.map((item) => {
            item.canDisable = res.data;
            return item;
          });
        }
      } catch (error) {
        this.tableData = this.tableData.map((item) => {
          item.canDisable = true;
          return item;
        });
      }
    },
    onFormClose() {
      this.formDialog = false;
      this.currentId = null;
    },
    onFormCancel() {
      this.formDialog = false;
      this.currentId = null;
      this.multipleFormDialog = false;
    },
    // 批量：启用/禁用
    onMultipleToggleDisable(flag = false) {
      if (this.multipleSelected.length <= 0) {
        return false;
      }
      let { ids, nameString, unableControl } = FormatMultipleItemIds({
        multipleSelectes: this.multipleSelected,
        nameKey: "id",
      });
      let message = GetTableDisableMessage({
        total: this.multipleSelected.length,
        ableControlCount: ids.length,
        unableControlCount: unableControl.length,
        nameString: nameString,
        flag: flag,
        ableText: "启用",
        unAbleText: "禁用",
      });
      this.$msgbox({
        title: `${flag ? "禁用" : "启用"}提示`,
        message: message,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            if (ids.length <= 0) {
              this.$message.info("无有效数据可操作");
              done();
              return;
            }
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            ToogleDisableByIds(ids, flag)
              .then(() => {
                this.$message.success("操作成功");
                this.multipleSelected = [];
                this.onRefresh();
              })
              .catch((err) => {
                ShowApiError("操作请求错误",err );
              })
              .finally(() => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
              });
          } else {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "";
            done();
          }
        },
      });
    },

    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    toggleDisableRow(row, index) {
      if (row.canDisable) {
        ToogleDisableByIds([row.id], !row.disabled).then(() => {
          row.disabled = !row.disabled;
          this.$set(this.tableData, index, row);
          this.$message.success("操作成功!");
        });
      } else {
        this.$message.warning("没有权限执行此操作!");
      }
    },

    navToSolutions() {
      this.$router.push({
        name: "SolutionsTagList",
      });
    },

    onFormSuccess() {
      if (isEmprty(this.currentId)) {
        this.page = 0;
      }
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-image__inner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .preview-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &.contain {
      object-fit: contain;
    }
  }
}
</style>
<style lang="scss">
.series-file-list {
  width: 100%;
  .list-item {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 10px 0;
    color: #4dbeff;
    cursor: pointer;
    overflow: hidden;
    border-bottom: 1px solid #f1f1f1;
    a {
      color: inherit;
    }
  }
}
</style>
